import React from "react"
import Navbar from "../../components/navbar"
import BackgroundShape from "../../components/backgroundShape"
import Container from "../../components/container"
import Header from "../../components/header"
import Head from "../../components/head"
import Layout from "../../components/layout"
import OnboardingBlock from "../../components/new/onboardingBlock/onboardingBlock"
import OnboardingCardSm from "../../components/onboardingCardSm"
import { Link } from 'gatsby'

const Shipping = () =>  {
    return (
        <Layout navbar="darkblue">
        <Head title="Payments" description="Learn how to connect with multiple vendors, manage your inventory, sales, customers and more with our step-by-step tutorials"/>
            <BackgroundShape style={{shape: "clip-fixed-small", color: "darkblue", particles: true}}/>
                <Container style={{classes: "container" }}>
                <div className="position-relative">
                    <Header content={{title: "Payments", text: "It can be tricky to keep track of your vendor payments if you don’t have a well established strategy and system. Automating your payments will help you save time and ensure all payments are processed correctly. "}} style={{color: "text-center"}}/>
                    <Link to='/onboarding' className='btnOn dark-bg' style={{position:'absolute', top: '246px', left:'-14px' }}>← Back</Link>
                </div>
                </Container>
                <Container style={{classes: "container mb-5" }}>
                    <div style={{background: '#F4F6F8', padding:'40px 40px', margin:'2rem -15px 0 -15px'}} data-sal="slide-up" data-sal-delay='200' data-sal-easing="ease" data-sal-duration="300">
                        <p style={{fontWeight:'700', marginTop: '8px', textAlign:'center'}}>How are you going to <a href="https://help.jetti.io/hc/en-us/articles/8927912027548-Commission-rates-at-a-vendor-level" target="_blank"><span style={{color:'#3b77ce'}}>pay</span></a> your vendors?</p>
                        <p style={{fontWeight:'300', marginTop: '8px', textAlign:'center'}}>Onport can automatically apply commissions rates to line items as they're imported, and you can define the commission rate type.</p>
                        <div className="row mt-5">
                            <div className="col-12 col-md-6">
                                <OnboardingBlock icon='commission-rates' title='Percentage of the retail price' description='Set a commission base on the percentage of the retail price of each item sold.'/>
                            </div> 
                            <div className="col-12 col-md-6">
                                <OnboardingBlock icon='reasons' title='Fixed amount' description='Set a commission base on a fixed amount of commission of each item sold.'/>
                            </div> 
                        </div>
                    </div>
                    <div style={{background: '#F4F6F8', padding:'40px 40px', margin:'2rem -15px 0 -15px'}} data-sal="slide-up" data-sal-delay='200' data-sal-easing="ease" data-sal-duration="300">
                        <p style={{fontWeight:'700', marginTop: '8px', textAlign:'center'}}>Are you going to add <span style={{color:'#3b77ce'}}>landed costs?</span></p>
                        <p style={{fontWeight:'300', marginTop: '8px', textAlign:'center'}}>Apart from the cost price you can also add landed cost to each product purchased<br/>which is a sum of expenses associated with shipping a product, typically costs connected with international shipping like taxes and fees.</p>
                        <div className="row mt-5">
                            <div className="col-12 col-md-6">
                                <OnboardingBlock title='Yes' link='https://help.jetti.io/hc/en-us/articles/4409659231506-Buy-now-pay-later-payment-services' description='lIt will be added a landed cost to each order purchasedl'/>
                            </div> 
                            <div className="col-12 col-md-6">
                                <OnboardingBlock title='No' description='No additional cost will be added to the order purchased. '/>
                            </div> 
                        </div>
                    </div>
                    <div style={{background: '#F4F6F8', padding:'40px 40px', margin:'2rem -15px 0 -15px'}} data-sal="slide-up" data-sal-delay='200' data-sal-easing="ease" data-sal-duration="300">
                        <p style={{fontWeight:'700', marginTop: '8px', textAlign:'center'}}>Where do you want the <span style={{color:'#3b77ce'}}>commission</span> to be calculated</p>
                        <div className="row mt-5">
                            <div className="col-12 col-md-4">
                                <OnboardingBlock icon='order' link='https://help.jetti.io/hc/en-us/articles/8927912027548-Commission-rates-at-a-vendor-level' title='In the full amount paid by the costumer' description='The commission will be based on the full price of the order.'/>
                            </div> 
                            <div className="col-12 col-md-4">
                                <OnboardingBlock icon='commission-rates' link='https://help.jetti.io/hc/en-us/articles/8957595384348-Not-using-commission-rates' title='Not using commission rates' description='If you are not using commission rates you can use Onport to maintain the cost prices of your items and pay your vendors.'/>
                            </div> 
                            <div className="col-12 col-md-4">
                                <OnboardingBlock icon='label' link='https://help.jetti.io/hc/en-us/articles/8957593120540-Commission-rates-at-a-product-level' title='In the retail price of each item' description='The commission will be based on the price of the item in the inventory feed.'/>
                            </div> 
                        </div>
                    </div>
                    <div style={{background: '#F4F6F8', padding:'40px 40px', margin:'2rem -15px 0 -15px'}} data-sal="slide-up" data-sal-delay='200' data-sal-easing="ease" data-sal-duration="300">
                        <p style={{fontWeight:'700', marginTop: '8px', textAlign:'center'}}>When should your <a href="https://help.jetti.io/hc/en-us/articles/8483535585308-Defining-when-the-vendors-get-paid" target="_blank"><span style={{color:'#3b77ce'}}>dropship providers</span></a> get paid?</p>
                        <p style={{fontWeight:'300', marginTop: '8px', textAlign:'center'}}>You can define when your dropship providers are paid, and once they are, the information will appear in the payment report.</p>
                        <div className="row mt-5">
                            <div className="col-12 col-md-6">
                                <OnboardingBlock title='Before shipping to customers' description='Pay the dropship providers before the items are shipped to the customers.'/>
                            </div> 
                            <div className="col-12 col-md-6">
                                <OnboardingBlock title='After shipping to customers' description='Pay the dropship providers after the items are shipped to the customers.'/>
                            </div> 
                            <div className="col-12 col-md-6 mt-5">
                                <OnboardingBlock title='Once items have been delivered' description='Pay the dropship providers after items have been successfully delivered to the customers. Important: Only works when using the USPS integrations.'/>
                            </div> 
                            <div className="col-12 col-md-6 mt-5">
                                <OnboardingBlock title='Manually (custom)' description='You can define with your vendor the frequency with which invoices are raised.'/>
                            </div> 
                        </div>
                    </div>
                    <div style={{background: '#F4F6F8', padding:'40px 40px', margin:'2rem -15px 0 -15px'}} data-sal="slide-up" data-sal-delay='200' data-sal-easing="ease" data-sal-duration="300">
                        <p style={{fontWeight:'700', marginTop: '8px', textAlign:'center'}}>How are you going to sync <span style={{color:'#3b77ce'}}>vendor payments?</span></p>
                        <p style={{fontWeight:'300', marginTop: '8px', textAlign:'center'}}>When setting up a new vendor it is important to be mindful of how are you going to manage the payout to your vendor.<br/>In Onport you have the option to do it manually through bank transfers or automatically thought a payment platform service.</p>
                        <div className="row mt-5">
                            <div className="col-12 col-md-6">
                                <OnboardingBlock icon='automate' link='https://help.jetti.io/hc/en-us/articles/8499093750940-Automated-vendor-payments' title='Automatically' description='You can integrate with one of the payment platform services we have available in Onport. Important: Only works for the Advance Plan Subscriptions'/>
                            </div> 
                            <div className="col-12 col-md-6">
                                <OnboardingBlock icon='tax' link='https://help.jetti.io/hc/en-us/articles/8523140353180-Manual-vendor-payouts' title='Manually' description="You can manually adjust the Balance tab if you pay your vendors outside of Onport (through bank transfer or other platforms we don't support)."/>
                            </div> 
                        </div>
                    </div>
                    <div className="row">
                <OnboardingCardSm content={{
                    slug: 'returns', 
                    category: '', 
                    step:'← Previous',
                    src: "https://taact-brand-assets.netlify.app/assets/illustrations/inventory.svg", 
                    title: "Returns", 
                    }}/>
                    <OnboardingCardSm content={{
                    slug: 'tax-and-invoicing', 
                    category: '',
                    step:'Next →', 
                    src: "https://taact-brand-assets.netlify.app/assets/illustrations/send-order.svg", 
                    title: "Tax and Invoicing", 
                    }}/>
                </div>
                </Container>
        </Layout>
    )
}

export default Shipping